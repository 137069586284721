// {ExperimentEditor.tsx}

import React from 'react';
// import useStyles from './styles';
// import TEST_ID from './constants';
import { Box } from '@mui/material';
import {
  ExperimentData,
  ExperimentEditor as ExperimentEditorContent,
  JsonArrayToCsvBlob,
  downloadCsvBlob,
  getExperimentData,
  useAppDispatch,
  useAppSelector,
} from 'quil';
import { useLocation } from 'react-router-dom';
import { RunPackageDto } from 'quanterix-protos/run_data/v1/db_run_package';
import { InstrumentDataBody } from 'quanterix-protos/run_data/v1/db_instrument_data';
import { exportAnalysisSummary, getOpenExperimentData } from 'quil/state';
import { RpckUploadStatus } from 'quanterix-protos/type/rpck_types';
import { PlateDto } from 'quanterix-protos/run_data/v1/db_plate';
import { AssayDefinitionDto, AssayProtocolDto } from 'quanterix-protos/run_data/v1/db_assay';
import { RootState, AppDispatch } from '../store';
import UploadRpck from '../api/RpckApi';
import { DataManagementApi, dataMgr } from '../api/DataManagementApi';

export interface ExperimentEditorProps {
  // Props
}

export default function ExperimentEditor() {
  // const { classes, cx } = useStyles();
  const location = useLocation();
  const id: string = location.state.experimentId;

  const { experiment } = useAppSelector(
    (state: RootState) => state.openExperiment,
  );

  const dispatch: AppDispatch = useAppDispatch();

  const uploadAndSave = async (files: File[] | FileList) => {
    // TODO: expose setLoading as an openExperimentSlice action
    // dispatch(setLoading(true));

    const uploadResponse = await UploadRpck(files);
    const status = uploadResponse?.uploadStatus;
    
    if (status === RpckUploadStatus.RPCK_UPLOAD_STATUS_ALREADY_UPLOADED || status === RpckUploadStatus.RPCK_UPLOAD_STATUS_NEW_UPLOAD_SUCCESS || status === RpckUploadStatus.RPCK_UPLOAD_STATUS_UPLOADED_WITH_NEW_ID)
    {
      const rpckDetails = uploadResponse.details;
      const instrumentDataId = rpckDetails?.id;

      const instrumentDataCheck = await dataMgr.getInstrumentData({
        instrumentDataId,
      });
      // If the instrumentData record does not already exist, create it
      if (!instrumentDataCheck?.instrumentData) {
        const reqBody = InstrumentDataBody.fromPartial({
          rpckId: rpckDetails?.id,
          // createdAccountId: '',
          // organizationId: '',
          // blobUploadedTimestamp: Date.now(),
          blobAccountName: 'any',
          blobContainerName: rpckDetails?.instrumentSerialNumber,
          blobFileName: rpckDetails?.name,
          instrumentSerialNumber: rpckDetails?.instrumentSerialNumber,
        });
        await dataMgr.writeInstrumentData({
          body: reqBody,
        });
      }

      const updatedExperiment = RunPackageDto.fromPartial({
        runPackageId: id,
        data: {
          ...experiment.data,
          instrumentDataId: rpckDetails?.id,
        },
      });

      const updatedExperimentData: Partial<ExperimentData> = {
        experiment: updatedExperiment,
      };

      await DataManagementApi.saveExperiment(updatedExperimentData);

      // TODO: expose setLoading as an openExperimentSlice action
      // await dispatch(setLoading(false));

      // Reload instrument data after update
      await dispatch(getExperimentData(id));
    }
  };

  const handleUpload = (files: File[] | FileList) => {
    uploadAndSave(files).then();
  };

  const handleExportData = () => {
    dispatch(exportAnalysisSummary()).then((response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        const data = response.payload as {
          summary: any[];
          calibrationCurves: any[];
          samples: any[];
        };
        const summaryBlob = JsonArrayToCsvBlob(data.summary);
        const calBlob = JsonArrayToCsvBlob(data.calibrationCurves);
        const samplesBlob = JsonArrayToCsvBlob(data.samples);

        if (experiment) {
          const exp = experiment as RunPackageDto;
          const exportPrefix = exp?.data?.runPackageName;

          downloadCsvBlob(summaryBlob, `${exportPrefix}_summary_table`);
          downloadCsvBlob(calBlob, `${exportPrefix}_calCurves_table`);
          downloadCsvBlob(samplesBlob, 'samples_table.csv');
        }
      }
    });
  };

  const handleExportReport = () => {
    // TODO: pending requirement definition
    alert('This feature is not implemented');
  };

  const handleSave = () => {
    dispatch(getOpenExperimentData(null)).then((response) => {
      if (response.payload){
        const data = response.payload as {
          experiment: RunPackageDto;
          plate: PlateDto;
          assayProtocols: AssayProtocolDto[];
          assayDefinitions: AssayDefinitionDto[];
      }
        DataManagementApi.saveExperiment(data).then(() => dispatch(getExperimentData(id)));
      }
    })
    
  }

  return (
    <Box width="100%" height="100%">
      <ExperimentEditorContent
        experimentId={id}
        handleUploadRpck={handleUpload}
        onExportData={handleExportData}
        onExportReport={handleExportReport}
        onSave={handleSave}
      />
    </Box>
  );
}
